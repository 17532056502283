@import 'Colours';

.link {
  font-weight: 500;
  color: $paysimply-blue;
  text-decoration: none;

  &:hover {
    color: $paysimply-blue-link-hover;
  }
}
