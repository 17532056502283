$paysimply-blue: #2b4e7b;
$paysimply-blue-hover: #26446b;
$paysimply-blue-link-hover: #102540;
$paysimply-red: #bb1b20;

$paysimply-light-blue: #b3e3f7;

$paysimply-white-main: #ffffff;
$paysimply-white-accent: #fafafa;
$paysimply-black-main: #232222;
$paysimply-black-accent: #0d0d0d;

$state-success: #28a745;
$state-success-shadow: rgba(25, 135, 84, 0.386);
$state-warning: #a16500;
$state-danger: #dc3545;
$state-danger-shadow: #dc354623;
$state-warning-shadow: #a1650023;
$state-info: #00AEEF;

$border-light-gray: #c8c8c8;
$background-gray: #F3F3F3;