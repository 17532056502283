@import 'Colours';

#nav {
  --link-y-padding: 0.5em;
  --link-x-padding: 1.5em;
  --link-height: 1.5em;
  --navbar-height: 3.75rem;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: $paysimply-red;
  z-index: 10;
}

.navContent {
  margin: 0 auto;
}

.logoContainer {
  display: flex;
  align-items: center;
}

#Links a,
#Links button,
#Hamburger {
  cursor: pointer;
  border: none;
  background: none;
  color: inherit;
  font-weight: inherit;

  &:hover {
    color: inherit;
  }
}

#Links {
  z-index: 10;
  a,
  button, #LogoutBtn, .HelpCentreButton {
    padding: var(--link-y-padding) var(--link-x-padding);
    box-sizing: content-box;
    position: relative;
    text-align: center;
    text-decoration: none !important;
    &:hover, &:focus{
      text-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
    }
  }
  .HelpCentreButton, #LogoutBtn {
    font-weight: 500 !important;
    font-family: inherit;
    font-size: inherit;
    text-align: start;
  }
}

.logo {
  display: block;
  aspect-ratio: 232 / 93;
  height: calc(var(--navbar-height) - 20px);
  position: relative;
  margin: 10px 10px 10px 0;
  display: flex;
  align-items: center;
}

.text {
  color: white;
  font-size: 1.2em;
  font-weight: 500;
  padding-top: 5px;
  margin-bottom: 0;
}

.row {
  height: var(--navbar-height);
}

@media only screen and (max-width: 541px) {
  .logoContainer {
    padding: 0 10px;
  }

  .text {
    font-size: 4.5vw;
  }
  .logo {
    height: 8.5vw;
    margin-block: 7px;
  }
}

@media only screen and (max-width: 429px) {
  .logoContainer,#Hamburger {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 650px) {
  #nav {
    transition: background-color 0.25s;
    &.atTop {
      background-color: $paysimply-light-blue;
      border-bottom: none;

      .text,
      & ~ .navContent .text {
        height: 0;
      }
    }
  }

  .navContent {
    padding: 0 1rem;
    font-size: 0.9em;
  }

  .corners {
    display: none;
  }

  .logo {
    margin-left: 0;
  }

  .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 500;
    color: white;
  }

  #Links {
    display: flex;
    align-items: center;
    gap: 1em;

    #SignUpBtn {
      color: $paysimply-white-main;
      border-radius: 3px;
      line-height: 1;
      padding: 0 0.5em;
      height: 1.4em;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      opacity: 1 !important;

      &:hover {
        text-decoration: none !important;
        box-shadow: none;
      }
      &:focus {
        text-decoration: none !important;
        box-shadow: none;
      }
    }
  }

  .text {
    overflow: hidden;
    transition: height 0.25s;
  }
}

@media only screen and (max-width: 649.5px) {
  #nav {
    --link-y-padding: 0.3em;
    --link-x-padding: 1em;

  }

  .navContent {
    padding: 0;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .corners {
    width: 1.25em;
    height: 1.25em;
    margin: 0 0.5em;
    font-size: 1.5em;
    &:first-child {
      margin-right: 0;
    }
    &:nth-child(3) {
      margin-left: 0;
    }
  }

  #Hamburger {
    padding: 2px 5px 5px 5px;
    color: white !important;
  }

  .text {
    text-align: center;
    visibility: hidden;
  }
  .text.show {
    visibility: visible;
  }

  #Links {
    flex-basis: 100%;
    transition: height 0.3s;
    overflow: hidden;
    visibility: hidden;
    height: 0;
    background-color: $paysimply-red;
    padding-top: 2px;
    color: white;

    a,
    button {
      display: block;
      width: 8em;
      text-align: left;
      transition: background-color 0.25s;
      font-weight: 500;
    }
  }

  #nav ~ .navContent .text {
    display: block;
  }

  .authPage .corners {
    display: none !important;
  }

  .authPage .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .navContent.expanded #Links {
    height: calc(1 * (var(--link-height) + 2 * var(--link-y-padding))) !important;
    visibility: visible;
  }

  .session .navContent.expanded #Links {
    height: calc(1 * (var(--link-height) + 2 * var(--link-y-padding)));
  }

  .authPage .navContent.expanded #Links {
    height: calc(0 * (var(--link-height) + 2 * var(--link-y-padding))) !important;
  }

  .logoContainer {
    margin-inline: auto;
  }

}

.authPage .navContent #Links .authLinks {
  display: none;
}
