@import 'Colours.scss';

.footer {
  background-color: $paysimply-black-main;

  .miniFooter {
    max-width: 1570px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    padding: 12px 1rem 10px 1rem;
    height: 3.75em;
    align-items: center;

    @media only screen and (max-width: 600px) {
      align-items: start;
      padding: 10px 1.5em 10px 1.5em;
      height: auto;
    }
    
    a {
      color: white;
    }
    
    button.miniFooterLink, a.miniFooterLink {
      color: #b9b9b9 !important;
      margin-right: 25px !important;
      font-weight: 500 !important;
      font-size: 11.5pt !important;
      padding: 2px;

      &:focus-visible {
        outline: 1px solid white !important;
        border-radius: 2px;
      }
      
      &:hover {
        color: white !important;
        text-decoration: none !important;
      }

      @media only screen and (max-width: 400px) {
        margin-right: 0 !important;
        &:not(:last-child) {
          margin-bottom: 10px !important;
        }
      }
    }
    
    .footerLinks {
      display: flex;

      @media only screen and (max-width: 400px) {
        flex-direction: column;
      }
    }
  }

  .supportText{
    font-size: 11.5pt;
    font-weight: 300;
    a{
      font-weight: 500;
    }
  }

  .linkFooter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  #disclaimer {
    font-weight: 300;
    font-size: 0.9rem;
    color: white;
    margin: 1rem 0;
    margin-bottom: 2.5rem;
  }

  p {
    color: white;
  }

  .column {
    flex-basis: 0%;
    flex-grow: 2;
    padding: 0.2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:first-child {
      padding-top: 0;
      padding-left: 1.5rem;
      flex-grow: 4;
    }

    .logo {
      margin-top: 1.8em;
    }

    h3,
    a {
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }

    h3 {
      font-size: 1.1rem;
      font-weight: 500;
      margin-top: 1.8rem;
      padding-bottom: 0.3rem;
      color: white;
    }

    a {
      display: inline;
      font-size: 1rem;
      font-weight: 400;
      color: white;
      text-decoration: none !important;
      opacity: 0.8;
      &:hover, &:focus {
        opacity: 1;
      }
    }
  }

  .connectLinks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 0.7em;
  }

  #bottom {
    background-color: $paysimply-black-accent;

    .constFooter {
      flex-basis: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      padding-top: 0.7rem;
      padding-bottom: 0.4rem;
      width: 100%;
      column-gap: 20px;
      max-width: 1600px;
      margin: 0 auto;
      .badges {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 15px;
      }
      .bottomText {
        flex: 1;
        p {
          font-weight: 300;
          padding: 0;
          margin: 0;
          text-align: right;
          font-size: 0.7em;
          opacity: 0.8;
          line-height: 1.5em;
        }
      }
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  #DropBtn {
    background-color: $paysimply-red;
    color: $paysimply-white-main;
    border-radius: 3px;
    padding: 0.5em 0.7em;
    height: 2.5em;
    display: inline-flex;
    align-items: center;
    transition: background-color 0.1s;
    border: 2px solid $paysimply-white-main;
    font-size: 1rem;
    column-gap: 20px;
    font-family: inherit;
    cursor: pointer;
    &:focus{
      box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
    }
  }

  .dropContent {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 2.5em;
    // min-width: fit-content;
    min-width: 100%;
    box-shadow: 0px 4px 16px 0px #fff1;
    z-index: 1;
    border-radius: 3px;
    border: 2px solid $paysimply-white-main;
    overflow: hidden;
    background-color: $paysimply-red;
    transition: opacity 0.15s, transform 0.15s, visibility 0.15s;
    &.show {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: translateY(0);
    }

    button {
      color: white;
      min-width: 100%;
      font-size: 1rem;
      height: 2.5em;
      margin: 0 !important;
      font-weight: 400;
      text-decoration: none;
      border-radius: 0;
      display: block;
      white-space: nowrap;
      text-align: center;
      padding: 0.3em var(--link-x-padding);
      &:hover, &:focus-visible {
        background-color: $paysimply-white-main;
        border-color: $paysimply-white-main;
        color: black;
      }
    }
  }

  @media only screen and (max-width: 767.9px) {
    .column {
      flex-basis: 100%;
      padding: 1rem 1.5rem;

      h3 {
        margin-top: 1rem;
      }
    }
  }

  
  @media only screen and (max-width: 1155px) {
    .miniFooter {
      flex-direction: column;
      p {
        margin-top: 10px;
      }
    }
  }
  
  @media only screen and (max-width: 600.9px) {
    .constFooter {
      display: block !important;

      .bottomText {
        padding-top: 10px;
        p {
          text-align: left !important;
        }
      }
    }
  }
}
