@import 'Colours';

.errorPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  flex-grow: 1;
  text-align: center;

  h1 {
    margin-bottom: 20px;
    font-size: 17pt;
  }

  p {
    margin-bottom: 30px;
    font-size: 13pt;
  }

  button {
    padding: 15px;
  }
}
